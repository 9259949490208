.pricing-plan {
  background-color: var(--secondary-background-color);

  aspect-ratio: 9/16;

  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  position: relative;

  overflow: hidden;

  h2 {
    margin: 0;
  }

  &__badge{
    background-color: var(--primary-color);
    border-radius: 6px;
    padding: 2px 30px;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(45deg) translateX(30px) translateY(-10px);
  }

  &__name {
    text-align: center;
  }

  &__price {
    display: flex;
    justify-content: center;

    &__cents {
      font-size: 60%;
      transform: translateY(3px);
    }

    &__interval {
      font-size: 60%;
      font-weight: normal;
      transform: translateY(11px);
    }
  }

  &__description {
    font-style: italic;
    opacity: 85%;
    margin: 12px 0;
  }

  &__benefits {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 12px;

    &__item {
      display: flex;
      gap: 8px;

      &__icon {
        border-radius: 100%;
        height: 20px;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__text {
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: auto;
  }
}
